export function formatMoney(amount, currency) {
  if (!currency) {
    currency = {
      precision: 3,
      thousand_separator: ".",
      decimal_separator: ","
      //symbol: "USD"
    };
  }

  amount = amount / 1000;

  let {
    precision,
    decimal_separator,
    thousand_separator
    //symbol
  } = currency;

    try {
        precision = Math.abs(precision);
        precision = isNaN(precision) ? 2 : precision;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(
            (amount = Math.abs(Number(amount) || 0).toFixed(precision))
        ).toString();
        let j = i.length > 3 ? i.length % 3 : 0;

        //let moneySymbol = symbol;

        return (
            //moneySymbol +
            " " +
            negativeSign +
            (j ? i.substr(0, j) + thousand_separator : "") +
            i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand_separator) +
            (precision
                ? decimal_separator +
                Math.abs(amount - i)
                    .toFixed(precision)
                    .slice(2)
                : "")
        );
    } catch (e) {
        //console.log(e);
    }
}
